import React from "react";
import { graphql } from "gatsby";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core/index";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import theme, { ooTextWidth, grey8 } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import SEO from "../components/element/SEO";


const seo = {
  title: "Legal Notice - Oberion",
  description: null,
};

function Legal(props) {
  const { data: { legal: { edges } } } = props;
  const { node: { childMarkdownRemark: { frontmatter, html } } } = edges[0];

  if (frontmatter.title !== "Legal Notice") {
    throw new Error(
      "Error with 'legal.md'. GraphQL query did not deliver expected results. Check markup, template and GraphQL query for consistency!",
    );
  }
  const pageBanner = (<PageBanner heading={frontmatter.title} />);

  return (
    <Layout hideNewsletter>
      <SEO {...seo} />
      {pageBanner}
      <LayoutSetBackground backgroundColor={grey8} color={theme.palette.secondary.contrastText}>
        <LayoutColumn justifyContent="center"
                      textAlign="left"
                      maxWidth={ooTextWidth}>

          <Typography component="span">
            <div
              className="legal-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Typography>
        </LayoutColumn>
      </LayoutSetBackground>
    </Layout>
  );
}

// noinspection JSUnusedGlobalSymbols
export const legalNoticeQuery = graphql`
{
  legal: allFile(
    filter: {
      internal: {
        mediaType: {eq: "text/markdown"}}, 
      name: {eq: "legal-notice"}, 
      sourceInstanceName: {eq: "legal"}}) {
    edges {
      node {
        childMarkdownRemark {
          html
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
}
`;

Legal.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Legal;